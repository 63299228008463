import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from 'src/app/models/user.model';
import { Order, OrderProduct } from 'src/app/models/order.model';
import { Address } from 'src/app/models/address.model';
import { Product } from 'src/app/models/product.model';
import { UserService } from 'src/app/services/user/user.service';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  category: string;
  order: Order;
  cntrProducts: BehaviorSubject<number>;

  lstProducts: Product[];

  URL = "https://ncvznkh234.execute-api.us-east-1.amazonaws.com/v1";

  constructor(
    private http: HttpClient,
    private userService: UserService,
  ) {
    this.cleanShopService();
  }

  public cleanShopService() {
    this.cntrProducts = new BehaviorSubject(0);
    this.order = new Order();
    this.order.iva = 0;
    this.order.deliveryTimeRange = "";
    this.order.lstProducts = new Array<OrderProduct>();
    this.order.dateCreated = new Date();
    this.order.address = new Address();
  }

  public getProducts(): Observable<any> {
    return this.http.get(this.URL + "/product/getListProduct")
      .pipe(
        map(results => {
          return results;
        })
      );
  }

  public getProduct(id: string): Observable<any> {
    return this.http.get(this.URL + "/product/getListProduct?id=" + id)
      .pipe(
        map(results => {
          return results;
        })
      );
  }

  public getCartItemCount() {
    return this.cntrProducts;
  }


  public addToCar(_product) {
    var newProduct = _product as OrderProduct;
    let exist: number = this.order.lstProducts.filter(product => product.sku == newProduct.sku).length;
    if (exist > 0) {
      let position: number = this.order.lstProducts.findIndex(product => product.sku == newProduct.sku);
      this.order.lstProducts[position].quantity++;
    } else {
      newProduct.quantity = 1;
      this.order.lstProducts.push(newProduct);
      this.cntrProducts.next(this.cntrProducts.value + 1);
    }

    this.calculateSubtotal();
  }

  public decreaseProduct(product: OrderProduct) {
    this.calculateSubtotal();
  }

  public removeProduct(product: OrderProduct) {
    var index: number = 0;
    this.order.lstProducts.forEach(p => {
      if (p.sku == product.sku) {
        this.order.lstProducts.splice(index, 1);
        this.cntrProducts.next(this.cntrProducts.value - 1);
      }
      index++;
    });
      this.calculateSubtotal();
  }

  public calculateSubtotal() {

    let total = 0;
    let subtotal = 0;
    let discount = 0;
    this.order.lstProducts.map(
      product => {
        subtotal += product.price;
      }
    );

    if (subtotal >= 400) {
      if (subtotal == 400) {
        discount = 20;
      } else if (subtotal > 400 && subtotal <= 600) {
        discount = 40;
      } else if (subtotal > 600 && subtotal <= 800) {
        discount = 60;
      } else if (subtotal > 800 && subtotal <= 1000) {
        discount = 80;
      } else if (subtotal > 1000 && subtotal <= 1200) {
        discount = 100;
      } else if (subtotal > 1200) {
        discount = 150;
      }
    }

    this.order.discount = discount
    this.order.subtotal = subtotal
    this.order.total = subtotal - discount
  }

  public updateStatusOrder(orderNumber: string): Observable<any> {
    var json_object = {
      "orderNumber": orderNumber,
      "statusCode": "6035bdd58af75121c895939a"
    }
    return this.http.put(this.URL + "/product/updateStatusOrder", json_object);
  }

  public sendOrder(user: User, order: Order, typePay: string): Observable<any> {
    var address = order.address.street + " " + this.order.address.outdoorExt;
    if (order.address.outdoorInt) {
      address += ", int: " + order.address.outdoorInt;
    } else {
      address += ", int: s/n ";
    }

    address += order.address.suburb + ", " + order.address.townHall + ", " + order.address.postalCode + ", " + order.address.city;


    var json_object = {
      "idUser": user.id,
      "idClient": user.client,
      "address": address,
      "cp": order.address.postalCode,
      "total": order.total,
      "discount": order.discount,
      "iva": order.iva,
      "deliveryTime": order.deliveryTimeRange,
      "shippingCost": order.shippingcost,
      "subtotal": order.subtotal,
      "lstProduct": order.lstProducts,
      "status": "5d712d0e2387002350e41911",
      "typePay": typePay,
      "deliveryNote": order.deliveryNote,
      "personal1": order.address.personal,
      "personal2": order.address.personal2,
      "phone1": order.address.phone,
      "mobile1": order.address.cellphone
    }

    return this.http.post(this.URL + "/product/sendOrder", json_object);
  }

  public inOrder(sku: string, lstProducts: Array<OrderProduct>): boolean {
    var inOrder = false;
    if (!isNullOrUndefined(lstProducts)) {
      const exist: number = lstProducts.filter(orderProduct => sku == orderProduct.sku).length;
      if (exist > 0) {
        inOrder = true
      }
    }
    return inOrder;
  }

}
