import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ModalController } from '@ionic/angular';
import { UserService } from 'src/app/services/user/user.service';
import { isNullOrUndefined } from 'util';
import { ShopService } from 'src/app/services/shop/shop.service';

@Component({
  selector: 'app-address-manager',
  templateUrl: './address-manager.page.html',
  styleUrls: ['./address-manager.page.scss'],
})
export class AddressManagerPage implements OnInit {
  addressFrm: FormGroup;
  personFrm: FormGroup;

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    public userService: UserService,
    public shopService: ShopService
  ) {
    this.userService.address.city = 'Cuidad de México';
    


    this.addressFrm = this.formBuilder.group({
      street: ['', [Validators.required, Validators.pattern('[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF0-9. ]+')]],
      outdoorExt: ['', [Validators.required, Validators.pattern('[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF0-9. ]+')]],
      outdoorInt: ['', []],
      suburb: ['', [Validators.required, Validators.pattern('[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF. ]+')]],
      postalCode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern('[0-9]+')]],
      townHall: ['', [Validators.required, Validators.pattern('[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF. ]+')]],
      city: ['', [Validators.required, Validators.pattern('[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF. ]+')]],
    });

    this.personFrm = this.formBuilder.group({
      personal: ['', [Validators.required, Validators.pattern('[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF. ]+')]],
      personal2: ['', [Validators.pattern('[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF. ]+')]],
      phone: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]+')]],
      cellphone: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]+')]],
    });

    var name = this.userService.user.name;
    if (!isNullOrUndefined(this.userService.user.firstSurname)) {
      name += " " + this.userService.user.firstSurname;
    }

    if (!isNullOrUndefined(this.userService.user.secondSurname)) {
      name += " " + this.userService.user.secondSurname;
    }

    

    var person = {
      personal: name,
      personal2: '',
      phone: isNullOrUndefined(this.userService.user.phone) ? this.userService.user.phone : '',
      cellphone: isNullOrUndefined(this.userService.user.cellphone) ? this.userService.user.cellphone : ''
    }
    this.addressFrm.patchValue(this.userService.address);
    this.personFrm.patchValue(person);

  }

  ngOnInit() {
  }

  doUpdateAddress() {
    if (this.addressFrm.valid && this.personFrm.valid) {
      this.closeComponent();
      this.userService.address.street = this.addressFrm.value.street;
      this.userService.address.outdoorExt = this.addressFrm.value.outdoorExt;
      this.userService.address.outdoorInt = this.addressFrm.value.outdoorInt;
      this.userService.address.suburb = this.addressFrm.value.suburb;
      this.userService.address.townHall = this.addressFrm.value.townHall;
      this.userService.address.city = this.addressFrm.value.city;
      this.userService.address.postalCode = this.addressFrm.value.postalCode;

      this.userService.address.personal = this.personFrm.value.personal;
      this.userService.address.personal2 = this.personFrm.value.personal2;
      this.userService.address.phone = this.personFrm.value.phone;
      this.userService.address.cellphone = this.personFrm.value.cellphone;
      if (isNullOrUndefined(this.userService.address.id)) {

        if (isNullOrUndefined(this.userService.address.outdoorInt) || this.userService.address.outdoorInt == "") {
          this.userService.address.outdoorInt = "s/n";
        }
        this.userService.lstAddress.push(this.userService.address);
        var id = 0;
        this.userService.lstAddress.forEach(address => {
          id++;
          address.id = id.toString();
        });
      } else {
        this.userService.lstAddress.forEach((address) => {
          if (this.userService.address.id == address.id) {
            address = this.userService.address;
          }
        });
      }
      this.userService.updateAddress(this.userService.user.id, this.userService.lstAddress).subscribe((data) => {
      });
      this.shopService.order.address = this.userService.address;
    } else {
      this.addressFrm.markAllAsTouched();
    }
  }

  closeComponent() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
