import { Product } from './product.model';
import { Address } from './address.model';

export interface OrderProduct {
    id: string;
    sku: string;
    name: string;
    unit: string;
    price: number;
    urlImage: string;
    description: string;
    provider: string;
    quantity: number;
    amount: number;
    note:string;
    subtotal: number;
    iva: number;
    discount: number;
}

export class Order {

    total: number;
    subtotal: number;
    discount: number;
    shippingcost: number;
    iva: number;
    orderNumber: string;
    deliveryTimeRange: string;
    lstProducts: OrderProduct[];
    address: Address;
    dateCreated: Date;
    deliveryNote: String;

    construtor() {
        this.orderNumber = this.orderNumber;
        this.total = this.total;
        this.subtotal = this.subtotal;
        this.discount = this.discount;
        this.shippingcost = this.shippingcost;
        this.iva = this.iva;
        this.lstProducts = new Array<OrderProduct>();
        this.address = new Address();
        this.dateCreated = new Date();
        this.deliveryNote = this.deliveryNote;
    }
}
