import { Injectable, ElementRef} from '@angular/core';

import { ToastController, NavController, AlertController, LoadingController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  public user: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    public loadingController: LoadingController,
    private toastController: ToastController,
    private nav: NavController,
    public alertController: AlertController,
    public translate: TranslateService,
  ) {
  }

  removeConform(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: 'Confirm!',
        message: 'Are you sure you want to remove this item',
        buttons: [
          {
            text: this.translate.instant("app.close"),
            role: 'cancel',
            cssClass: 'secondary',
            handler: (cancel) => {

              resolve('cancel');
            }
          }, {
            text: this.translate.instant("app.ok"),
            handler: (ok) => {

              resolve('ok');
            }
          }
        ]
      });

      alert.present();
    });
  }

  async openLoader() {
    const loading = await this.loadingController.create({
      message:  this.translate.instant("app.wait"),
      duration: 2000
    });
    await loading.present();
  }
  async openInfLoader() {
    const loading = await this.loadingController.create({
      message:  this.translate.instant("app.wait")
    });
    await loading.present();
  }
  async closeLoading() {
    return await this.loadingController.dismiss();
  }

  async getBlob(webPath: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const blob = await fetch(webPath).then(r => r.blob());
        resolve(blob);
      } catch (e) {
        console.error('Unable to make blob', e);
        reject(e);
      }
    });
  }

  public getDefaultLanguage() {
    var language = this.translate.getBrowserLang();
    this.translate.setDefaultLang(language);
    return language;
  }

  public setLanguage(setLang) {
    this.translate.use(setLang);
  }

  public validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  public formatDate(date: Date): string {
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let m = date.getMonth();
    m++;
    let month = m < 10 ? "0" + m : m;
    let dateFormated: string = day + "-" + month + "-" + date.getFullYear();
    return dateFormated;
  }

  public formatTime(date: Date): string {
    let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let timeFormated: string = hours + ":" + minutes;
    return timeFormated;
  }

  public animateCSS(element: ElementRef, animationName: string, keepAnimated = false) {
    const node = element.nativeElement;
    node.classList.add('animated', animationName)

    function handleAnimationEnd() {
      if (!keepAnimated) {
        node.classList.remove('animated', animationName);
      }
      node.removeEventListener('animationend', handleAnimationEnd)
    }

    node.addEventListener('animationend', handleAnimationEnd)
  }
}
