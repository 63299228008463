export class Address {
    id: string;
    personal: string;
    personal2: string;
    street: string;
    outdoorExt: string;
    outdoorInt: string;
    suburb: string;
    postalCode: string;
    townHall: string;
    city: string;
    phone: string;
    cellphone: string;

    constructor() {
        this.id = this.id;
        this.personal = this.personal;
        this.personal2 = this.personal2;
        this.street = this.street;
        this.outdoorExt = this.outdoorExt;
        this.outdoorInt = this.outdoorInt;
        this.suburb = this.suburb;
        this.postalCode = this.postalCode;
        this.townHall = this.townHall;
        this.city = this.city;
        this.phone = this.phone;
        this.cellphone = this.cellphone
    }
}