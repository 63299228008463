import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { FooterComponent } from 'src/app/components/footer/footer.component';


@NgModule({
    declarations: [HeaderComponent, FooterComponent],
    imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule],
    exports: [HeaderComponent, FooterComponent ]
})

export class ComponentsModule { }