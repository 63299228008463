import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OpenPay } from 'src/app/models/openPay.model';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class OpenpayService {

  ABASTO_ID: string = "mmkjiqu2hjgdiqwbmuo9";
  PUBLIC_KEY: string = "pk_8d281395d1604311b3ea9e10dbf459ee";
  PRIVATE_KEY: string = "sk_fbabba7b64e64fce9e40f6fd24a6b66c";
  URL: string = "https://sandbox-api.openpay.mx/v1/";
  HEADERS: HttpHeaders = new HttpHeaders({ 'authorization': 'Basic ' + window.btoa(this.PRIVATE_KEY + ':') });

  openPay: OpenPay = new OpenPay();

  constructor(
    private http: HttpClient
  ) {

  }

  public createClient(email: string, name: string, lastName?: string): Observable<any> {

    var client = {
      "name": name,
      "last_name": !isNullOrUndefined(lastName) ? lastName : "",
      "email": email,
      "requires_account": false
    }

    return this.http.post(this.URL + this.ABASTO_ID + "/customers/", client, { headers: this.HEADERS })
      .pipe(
        map(results => {
          return results;
        })
      );

  }


}
