import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { DeviceGuard } from './guards/device/device.guard';
import { NoLogedGuard } from 'src/app/guards/noLoged/no-loged.guard';
import { AuthGuard } from 'src/app/guards/auth/auth.guard';
import { OrderDetailGuard } from 'src/app/guards/orderDetail/order-detail.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './pages/home/home.module#HomePageModule', canActivate: [DeviceGuard] },
  { path: 'whatsapp', loadChildren: './pages/about/about.module#AboutPageModule' },
  { path: 'chat', loadChildren: './pages/about/about.module#AboutPageModule' },
  { path: 'WhatsApp', loadChildren: './pages/about/about.module#AboutPageModule' },
  { path: 'contact', loadChildren: './pages/contact/contact.module#ContactPageModule' },
  { path: 'login', loadChildren: './components/login/login.module#LoginPageModule', canActivate: [NoLogedGuard] },
  { path: 'register', loadChildren: './components/login/components/register/register.module#RegisterPageModule' },

  { path: 'profile', loadChildren: 'src/app/pages/profile/profile.module#ProfilePageModule', canActivate: [AuthGuard]},
  { path: 'c', loadChildren: 'src/app/pages/shop/shop.module#ShopPageModule' },
  { path: 'history', loadChildren: 'src/app/pages/history/history.module#HistoryPageModule' , canActivate: [AuthGuard]},
  { path: 'order', loadChildren: 'src/app/pages/shop/components/order/order.module#OrderPageModule'},
  { path: 'p', loadChildren: 'src/app/pages/product/product.module#ProductPageModule'},
  { path: 'history-detail/:folio', loadChildren: 'src/app/pages/history/components/history-detail/history-detail.module#HistoryDetailPageModule'},
  { path: 'favorites', loadChildren: 'src/app/pages/favorites/favorites.module#FavoritesPageModule', canActivate: [AuthGuard]},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
