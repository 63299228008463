import { NumericValueAccessor } from '@ionic/angular';

export class User {
    id: string;
    name: string;
    firstSurname: string;
    secondSurname: string;
    email: string;
    cellphone: string;
    phone: string;
    urlImg: string;
    client: string;
    firebaseToken: string;
    openPayToken: string;
    providerId: string;

    constructor() {
        this.id = this.id;
        this.name = this.name;
        this.firstSurname = this.firstSurname;
        this.secondSurname = this.secondSurname;
        this.email = this.email;
        this.cellphone = this.cellphone;
        this.phone = this.phone;
        this.urlImg = this.urlImg;
        this.client = this.client;
        this.firebaseToken = this.firebaseToken;
        this.openPayToken = this.openPayToken;
        this.providerId = this.providerId;
    }

}

/*export class UserModel {

    constructor(id: string, email: string, name?: string) {
        this.id = id;
        this.name = name;
        this.email = email;
    }
    public id: string;
    public email: string;
    public token: string;
    public name?: string;
    public profileurl?: string;
    public phonenumber?: string;

    public static fromDto(user: UserDto): UserModel {
        return new UserModel(user.id, user.email, user.name);
    }

    public static emptyDto(): UserDto {
        return {
            id: null,
            email: null,
            name: null,
            token: null,
            profileurl: null,
            phonenumber: null
        };
    }

    public toDto(): UserDto {
        return {
            id: this.id,
            token: this.token,
            name: this.name,
            email: this.email,
            profileurl:this.profileurl,
            phonenumber:this.phonenumber,
        };
    }
}

export interface UserDto {
    id: string;
    token:string;
    name?: string;
    email: string;
    profileurl:string;
    phonenumber:string;
}*/
