import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';

import { UtilService } from 'src/app/services/util/util.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public isLoged: boolean;
  public selectedPath: string;
  public pages = [];
  public pagenone = [];

  @ViewChild('menu', { static: false, read: ElementRef }) menuItems: ElementRef;

  constructor(
    private router: Router,
    private util: UtilService,
    public userService: UserService,
  ) {
      this.selectedPath = this.router.url;
      this.pages = [
        { title: 'Inicio', url: '/home',  },
        { title: 'Tienda', url: '/abasto',  },
        { title: 'WhatsApp', url: '/contact' },

      ];

    }
  ngOnInit() {
    document.getElementById("mobile-items").style.display = "none";
  }

  navigateto(url: string) {
    console.log(url);
    document.getElementById("mobile-items").style.display = "none";
    this.router.navigate([url]);
  }

  menuAction() {
    console.log(document.getElementById("mobile-items").style.display);
    if (document.getElementById("mobile-items").style.display == "block") {
      this.util.animateCSS(this.menuItems, "fadeOut");
      document.getElementById("mobile-items").style.display = "none";
    } else {
      document.getElementById("mobile-items").style.display = "block";
      this.util.animateCSS(this.menuItems, "fadeIn");
    }
  }

}
