import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import { User } from 'src/app/models/user.model';
import { Company } from 'src/app/models/company.model';
import { Address } from 'src/app/models/address.model';
import { Product } from 'src/app/models/product.model';

import { OpenpayService } from '../openpay/openpay.service';
import { Order } from 'src/app/models/order.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user: User;
  address: Address;
  lstAddress: Address[] = new Array<Address>();
  lstFavorites: string[];
  lstHistory: any;
  isLoged: boolean;

  desc: number;
  favoritesCntr: BehaviorSubject<number>;

  URL = "https://ncvznkh234.execute-api.us-east-1.amazonaws.com/v1";
  HEADERS: HttpHeaders;

  constructor(
    private http: HttpClient,
    private fireAuth: AngularFireAuth,
  ) {
    this.HEADERS = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

    this.cleanUserService();
  }

  public cleanUserService() {
    this.desc = 0;
    this.user = new User();
    this.address = new Address();
    this.lstFavorites = new Array<string>();
    this.favoritesCntr = new BehaviorSubject(0)
  }

  public getFavoritesItemCount() {
    return this.favoritesCntr;
  }

  public getUser(): Observable<any> {
    return this.fireAuth.authState.pipe(map(res => {
      return res;
    }));
  }

  public setUser(firebaseToken: string): Observable<any> {
    return this.http.get(this.URL + "/user?firebaseToken=" + firebaseToken).pipe(
      map(results => {
        return results;

      })
    );
  }

  public registerUser(user: User, company: Company): Observable<any> {
    var json_object = {
      "name": user.name,
      "firstSurname": user.firstSurname,
      "secondSurname": user.secondSurname,
      "email": user.email,
      "cellphone": user.cellphone,
      "phone": user.phone,
      "firebaseToken": !isNullOrUndefined(user.firebaseToken) ? user.firebaseToken : null,
      "openPayToken": !isNullOrUndefined(user.openPayToken) ? user.openPayToken : null,
      "idCompany": !isNullOrUndefined(company.id) ? company.id : null,
      "newCompany": !isNullOrUndefined(company.id) ? null : {
        "nombreComercial": !isNullOrUndefined(company.name) ? company.name : !isNullOrUndefined(company.id) ? null : user.name + " " + user.firstSurname + " " + user.secondSurname,
        "rfc": !isNullOrUndefined(company.rfc) ? company.rfc : null,
        "cp": !isNullOrUndefined(company.postalCode) ? company.postalCode : null,
      }
    }

    return this.http.post(this.URL + "/user", json_object, {})
      .pipe(
        map(results => {
          return results;
        })
      );
  }

  public registerSocialNetwork() {
    var json_object = {
      "name": this.user.name,
      "firstSurname": this.user.firstSurname,
      "secondSurname": this.user.secondSurname,
      "email": this.user.email,
      "urlImg": this.user.urlImg,
      "providerId": this.user.providerId,
      "firebaseToken": this.user.firebaseToken,
      "newCompany": {
        "nombreComercial": this.user.name + " " + this.user.firstSurname + " " + this.user.secondSurname
      }
    }
    var request = this.http.post(this.URL + "/user", json_object, {})
      .pipe(
        map(results => {
          return results;
        })
      );
    request.subscribe((requestObj) => {
      if (requestObj['success']) {
        this.user.id = requestObj['response'].id
        this.user.name = requestObj['response'].name
        this.user.email = requestObj['response'].email
      }
    });

  }

  public updateEmail(token: string, email: string) {
    return this.http.put(this.URL + "/user/updateEmail", { "token": token, "email": email }, {})
      .pipe(map(results => {
        return results;
      }));
  }

  public updateUser(user: User): Observable<any> {
    return this.http.put(this.URL + "/user", { "user": user })
      .pipe(map(results => {
        return results;
      }));
  }

  public updateAddress(idUser: string, lstAddress: Array<Address>): Observable<any> {
    return this.http.post(this.URL + "/product/addAddress", { "idUser": idUser, "lstAddress": lstAddress })
      .pipe(map(results => {
        return results;
      }));
  }

  public removeAddress(address: Address) {
    var index: number = 0;
    this.lstAddress.forEach(p => {
      if (p.id == address.id) {
        this.lstAddress.splice(index, 1);
      }
    })
  }

  public favorite(idUser: string, idProduct: string): Observable<any> {
    return this.http.post(this.URL + "/product/favorite", { "idUser": idUser, "idProduct": idProduct })
      .pipe(map(results => {
        return results;
      }));
  }

  public addFavorite(product: Product) {
    if (this.lstFavorites != null) {
      console.log("1");
      let exist: number = this.lstFavorites.filter(idProduct => product.sku == idProduct).length;
      if (exist > 0) {
        var lstTemp = new Array<string>();
        this.lstFavorites.forEach((id) => {
          if (id !== product.sku) {
            lstTemp.push(id);
          }
        })
        this.favorite(this.user.id, product.sku).subscribe((res) => {
          if (res.success) {
            this.lstFavorites = lstTemp;
            this.favoritesCntr.next(this.favoritesCntr.value - 1);
          }
        });
      } else {
        this.favorite(this.user.id, product.sku).subscribe((res) => {
      
          if (res.success) {
            this.lstFavorites.push(product.sku);
            this.favoritesCntr.next(this.favoritesCntr.value + 1);
          }
        });

      }
    } else {
      this.favorite(this.user.id, product.sku).subscribe((res) => {      
        if (res.success) {
          this.lstFavorites = [];
          this.lstFavorites.push(product.sku);
          this.favoritesCntr.next(this.favoritesCntr.value + 1);
        }
      });
    }
  }

  public isFavorite(product: Product): boolean {

    if (this.lstFavorites != null) {
      let exist: number = this.lstFavorites.filter(idProduct => product.id == idProduct).length;
      if (exist > 0) {
        return true
      } else {
        return false
      }
    }
    return false
  }

  public getHistory(idUser: String): Observable<any> {
    return this.http.get(this.URL + "/product/getUserOrders?idUser=" + idUser)
      .pipe(
        map(results => {
          return results;
        })
      );
  }
  public getFavorite(idUser: string): Observable<any> {
    return this.http.get(this.URL + "/product/favorite?idUser=" + idUser)
      .pipe(map(results => {
        return results;
      }));
  }
}
